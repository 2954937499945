.welcome-card .dialog-content {
  font-size: 15px;
  text-align: center;
  margin-bottom: 36px;
}
.welcome-card .dialog-content .img-welcome {
  margin-bottom: 16px;
}
.welcome-card .dialog-content .welcome-list {
  max-height: 120px;
  overflow-y: auto;
}
.welcome-card .dialog-content .welcome-list .welcome-text {
  margin-top: 10px;
  font-family: DFPFangYuanW7;
  color: #448816;
  font-size: 15px;
}
.welcome-card .dialog-content .welcome-list .welcome-text span {
  color: #67bea2;
}
.welcome-card .dialog-content .reset-pwd-text {
  margin-top: 30px;
  font-family: DFPFangYuanW7;
  color: #448816;
  font-size: 15px;
}
.welcome-card .bottom-btns {
  margin-bottom: 20px;
  text-align: center;
}
.welcome-card .bottom-btns .el-button {
  width: 175px;
}
.welcome-card .bottom-btns .el-button + .el-button {
  margin-left: 50px;
}
