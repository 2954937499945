.login-card .forget-pwd {
  position: absolute;
  top: 40px;
  left: 230px;
  font-size: 12px;
  color: #9bd277;
  cursor: pointer;
}
.login-card .forget-pwd:hover {
  color: #6aaf3d;
}
.login-card .login-input {
  width: 280px;
}
.login-card .el-input__prefix {
  display: inline-block;
  font-size: 12px;
  height: 40px;
  position: absolute;
  top: 0.4rem;
  left: 2px;
  cursor: pointer;
  overflow: hidden;
  opacity: 1;
}
.login-card .login-btn-box {
  text-align: center;
  position: relative;
}
.login-card .login-btn-box .button-item {
  width: 280px;
}
.login-card .login-btn-box .login-btn-mask {
  display: inline-block;
  width: 2.8rem;
  width: 280px;
  height: 42px;
  background: transparent;
  position: absolute;
  left: 50%;
  top: 0;
  margin-left: -140px;
}
.login-card .register-account {
  margin-top: 30px;
  font-size: 12px;
  text-align: center;
  color: #6aaf3d;
  font-family: DFPFangYuanW7 !important;
}
.login-card .register-account .span-register {
  color: #fcae2c;
  font-family: DFPFangYuanW7 !important;
}
.login-card .register-account .span-register:hover {
  color: #ff8626;
  font-family: DFPFangYuanW7 !important;
}
