
































































.welcome-card {
  .dialog-content {
    font-size: 15px;
    text-align: center;
    margin-bottom: 36px;
    .img-welcome {
      margin-bottom: 16px;
    }
    .welcome-list {
      max-height: 120px;
      overflow-y: auto;
      .welcome-text {
        margin-top: 10px;
        font-family: DFPFangYuanW7;
        color: #448816;
        font-size: 15px;

        span {
          color: #67bea2;
        }
      }
    }

    .reset-pwd-text {
      margin-top: 30px;
      font-family: DFPFangYuanW7;
      color: #448816;
      font-size: 15px;
    }
  }
  .bottom-btns {
    margin-bottom: 20px;
    text-align: center;
    .el-button {
      width: 175px;
      & + .el-button {
        margin-left: 50px;
      }
    }
  }
}
