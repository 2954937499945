















































































































































































































































































































































































































































































































































































.pwd-card {
  height: 350px;
  .el-form {
    padding-bottom: 0px;
    .sliding-verification {
      .el-form-item__content .el-button {
        width: 280px;
        // width: 2.8rem;
        height: 36px;
        line-height: 34px;
        font-size: 14px;
      }
      .is-success-verify {
        background: #ade787;
        cursor: default !important;
      }
    }
    .el-input {
      // width: 2.8rem;
      // height: 0.36rem;

      // .el-input__inner {
      //   height: 100%;
      // }
    }
    .verification-code {
      .el-form-item__content {
        .el-input {
          width: 150px;
          // width: 1.24rem;
          // height: 0.36rem;

          // .el-input__inner {
          //   height: 100%;
          // }
        }
        .get-code {
          display: inline-block;
          text-align: center;
          width: 120px;
          height: 36px;
          // width: 1.24rem;
          // height: 0.36rem;
          // line-height: 0.36rem;
          border-radius: 10px;
          margin-left: 12px;
          &.get-code-status1 {
            color: #595959;
            background: #e5e5e3;
            cursor: not-allowed;
          }
          &.get-code-status2 {
            color: #cb7e09;
            background: #ffecbe;
            cursor: pointer;
            &:hover {
              opacity: 0.8;
            }
          }
        }
      }
    }
    .form-empty {
      height: 0;
      margin-bottom: 0 !important;
      overflow: hidden;
    }
  }
  .bottom-btns {
    margin-bottom: 20px;
    margin-top: 50px;
    .el-button {
      width: 280px;
      // width: 2.8rem;
      margin-left: 100px;
    }
  }
  .step-3 {
    text-align: center;
    .bottom-btns {
      margin-top: 40px;
      margin-bottom: 20px;
      .el-button {
        margin-left: 0;
      }
    }
  }
}
