.register-card .el-form {
  padding-bottom: 0px;
}
.register-card .el-form .sliding-verification .el-form-item__content .el-button {
  width: 280px;
  height: 36px;
  line-height: 34px;
  font-size: 14px;
}
.register-card .el-form .sliding-verification .is-success-verify {
  background: #ade787;
  cursor: default !important;
}
.register-card .el-form .el-input {
  width: 280px;
}
.register-card .el-form .verification-code .el-form-item__content .el-input {
  width: 150px;
}
.register-card .el-form .verification-code .el-form-item__content .get-code {
  display: inline-block;
  text-align: center;
  width: 120px;
  height: 36px;
  line-height: 36px;
  border-radius: 10px;
  margin-left: 12px;
}
.register-card .el-form .verification-code .el-form-item__content .get-code.get-code-status1 {
  color: #595959;
  background: #e5e5e3;
  cursor: not-allowed;
}
.register-card .el-form .verification-code .el-form-item__content .get-code.get-code-status2 {
  color: #cb7e09;
  background: #ffecbe;
  cursor: pointer;
}
.register-card .el-form .verification-code .el-form-item__content .get-code.get-code-status2:hover {
  opacity: 0.8;
}
.register-card .el-form .form-empty {
  height: 0;
  margin-bottom: 0 !important;
  overflow: hidden;
}
.register-card .bottom-btns {
  margin-bottom: 20px;
}
.register-card .bottom-btns .el-button {
  width: 280px;
  margin-left: 100px;
}
.register-card .bottom-text {
  margin-top: 28px;
  text-align: center;
}
.register-card .bottom-text p,
.register-card .bottom-text span {
  color: #9bd277;
}
.register-card .bottom-text .line-login {
  margin-top: 15px;
  font-size: 12px;
  text-align: center;
  color: #6aaf3d;
  font-family: DFPFangYuanW7 !important;
}
.register-card .bottom-text .line-login .span-login {
  color: #fcae2c;
  font-family: DFPFangYuanW7 !important;
}
.register-card .bottom-text .line-login .span-login:hover {
  color: #ff8626;
  font-family: DFPFangYuanW7 !important;
}
