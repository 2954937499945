






































.reset-pwd-card {
  min-height: 225px;
  text-align: center;
  .dialog-content {
    padding: 10px 84px 50px;
    color: #448816;
    line-height: 20px;
    font-size: 15px;
    font-family: DFPFangYuanW7;
    text-align: center;
  }
  .bottom-btns {
    margin-bottom: 20px;
    text-align: center;
    .el-button {
      width: 175px;
      & + .el-button {
        margin-left: 50px;
      }
    }
  }
}
