.reset-pwd-card {
  min-height: 225px;
  text-align: center;
}
.reset-pwd-card .dialog-content {
  padding: 10px 84px 50px;
  color: #448816;
  line-height: 20px;
  font-size: 15px;
  font-family: DFPFangYuanW7;
  text-align: center;
}
.reset-pwd-card .bottom-btns {
  margin-bottom: 20px;
  text-align: center;
}
.reset-pwd-card .bottom-btns .el-button {
  width: 175px;
}
.reset-pwd-card .bottom-btns .el-button + .el-button {
  margin-left: 50px;
}
