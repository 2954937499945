.pwd-card {
  height: 350px;
}
.pwd-card .el-form {
  padding-bottom: 0px;
}
.pwd-card .el-form .sliding-verification .el-form-item__content .el-button {
  width: 280px;
  height: 36px;
  line-height: 34px;
  font-size: 14px;
}
.pwd-card .el-form .sliding-verification .is-success-verify {
  background: #ade787;
  cursor: default !important;
}
.pwd-card .el-form .verification-code .el-form-item__content .el-input {
  width: 150px;
}
.pwd-card .el-form .verification-code .el-form-item__content .get-code {
  display: inline-block;
  text-align: center;
  width: 120px;
  height: 36px;
  border-radius: 10px;
  margin-left: 12px;
}
.pwd-card .el-form .verification-code .el-form-item__content .get-code.get-code-status1 {
  color: #595959;
  background: #e5e5e3;
  cursor: not-allowed;
}
.pwd-card .el-form .verification-code .el-form-item__content .get-code.get-code-status2 {
  color: #cb7e09;
  background: #ffecbe;
  cursor: pointer;
}
.pwd-card .el-form .verification-code .el-form-item__content .get-code.get-code-status2:hover {
  opacity: 0.8;
}
.pwd-card .el-form .form-empty {
  height: 0;
  margin-bottom: 0 !important;
  overflow: hidden;
}
.pwd-card .bottom-btns {
  margin-bottom: 20px;
  margin-top: 50px;
}
.pwd-card .bottom-btns .el-button {
  width: 280px;
  margin-left: 100px;
}
.pwd-card .step-3 {
  text-align: center;
}
.pwd-card .step-3 .bottom-btns {
  margin-top: 40px;
  margin-bottom: 20px;
}
.pwd-card .step-3 .bottom-btns .el-button {
  margin-left: 0;
}
